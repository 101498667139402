import * as React from 'react';
import { Link } from 'gatsby';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import { PrismicProvider } from '@prismicio/react';
import AuthProvider from './src/components/auth/AuthContext';

import repositoryConfigs from './src/prismicPreviews';

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
      {element}
    </PrismicPreviewProvider>
  </AuthProvider>
);
