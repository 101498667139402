/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with drafts and unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */
import React from 'react'
import { linkResolver } from './linkResolver'

/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
const repositoryConfigs = [
  {
    repositoryName: 'kansascityzoo',
    accessToken:
      'MC5YaFkzN2hVQUFDUUFEOFJC.77-9eO-_vW4QMUgs77-9b--_ve-_ve-_ve-_vTrvv73vv73vv73vv73vv73vv71dP--_vWoNRe-_vV3vv73vv73vv70',
    linkResolver,
    componentResolver: {
      // general_content_page: React.lazy(() =>
      //   import('./templates/GeneralContentPage')
      // ),
      // newsroom: React.lazy(() => import('./templates/newsroom')),
    },
  },
]

export default repositoryConfigs
